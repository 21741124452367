@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

:root {
  --Sonic-Silver: #747475;
  --Glassy-1: radial-gradient(circle, #ffffff 35%, #ffffff 0%);
  --Glassy-2: linear-gradient(to bottom, #ffffff 47%, #ffffff 0%);
}

.glossy-background {
  border-radius: 4px;
  background: radial-gradient(
    121.49% 277.08% at 0% 0%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.glossy-border {
  border-radius: 4px;
  border: 1px solid transparent; /*2*/
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    border-box !important; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.text-gold {
  background: linear-gradient(
    90deg,
    #764e1b 0%,
    #d7aa2d 15.85%,
    #deb941 18.07%,
    #fffb9a 28.57%,
    #f4e57c 31.97%,
    #e7cb5a 36.55%,
    #deb941 40.89%,
    #d9ae32 44.88%,
    #d7aa2d 48.15%,
    #d9ad31 51.95%,
    #ddb63e 55.93%,
    #e5c653 59.99%,
    #f0dc70 64.1%,
    #fdf895 68.23%,
    #fffb9a 68.68%,
    #f9ee89 70.45%,
    #d7aa2d 80%,
    #cfa32c 81.46%,
    #764e1b 99.44%
  );
}

.quote-glassy {
  border-radius: 16px;
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid;
  border-image-source: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.countdown-glassy {
  border-radius: 16px;
  border-image-source: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid;
}

.custom-bg-count-down {
  border-radius: 16px;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.border-count-down {
  border-radius: 16px;
  border: 2px solid transparent; /*2*/
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.border-custom {
  border-radius: 50px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg, #bc852b 0%, #f4d58e 58%, #bc852b 100%)
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.bottom-nav-active {
  background: radial-gradient(
    121.49% 277.08% at 0% 0%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 100%;
  width: 64px;
  height: 64px;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}

.bg-glassy-2 {
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.bg-glassy-3 {
  border-top: 1px solid;

  border-image-source: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.47) 0%,
    rgba(255, 255, 255, 0) 110.84%
  );
}
.bg-glassy-4 {
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.border-glossy-3 {
  border: 0.51px solid transparent; /*2*/
  background: linear-gradient(
      112.83deg,
      rgba(255, 255, 255, 0.47) 0%,
      rgba(255, 255, 255, 0) 110.84%
    )
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
@tailwind base;
@tailwind components;
@tailwind utilities;
